<template>
  <div style="overflow: hidden;">
    <router-link to="/" tag="a">
      <img src="/images/schriftzug.png" style="height: 100%;"/>
    </router-link>
    <div class="header_container">
      <ul class="header_container__list">
        <li><a href="/index#home" class="header_container__list--link"> Home</a></li>
        <li><a href="/index#ablauf" class="header_container__list--link"> Unser Ablauf</a></li>
        <li><a href="/index#contact" class="header_container__list--link"> Kontakt</a></li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}

</script>

<style lang="scss" scoped>

div {
  img {
    margin-left: 20px;

    @include for-phone-only {
      font-size: 26px;
    }

    @include for-tablet-portrait-up {
      font-size: 26px;
    }

    @include for-desktop-up {
      font-size: revert;
    }
  }

  display: flex;
  display: -webkit-flex;
  margin: auto;
  padding: 0;
  height: 82px;
  left: 0px;
  top: 0px;
  background: $color_primary;

  p {
    text-align: center;
    margin: auto; /* Important */
    font-family: "Monte", serif;
    font-size: 30px;
    color: #fff;

    @include for-phone-only {
      font-size: 24px;
    }
  }

  .header_container {
    margin-right: 0;
    padding-right: 40px;

    @include for-phone-only {
      display: none;
    }

    &__list {
      display: inline-flex;
      list-style: none;
      align-content: center;
      align-items: center;
      align-self: center;

      &--link {
        color: white;
        text-decoration: unset;
        margin-right: 15px;
        font-weight: normal;

        &:hover {
          color: #d2d2d2;
        }
      }
    }
  }
}

</style>
