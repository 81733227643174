<template>
  <div>
    <Header title="Mein-Gutachter24.com"/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    Header
  }
}
</script>

<style>

body {
  margin: 0;
  padding: 0;
  background-color: #000000;
  color: #FFFFFF;
}

.textfield {
  padding: 6px;
  font-family: Monte, serif;
  font-style: normal;
  font-weight: bold;
  color: #065A60;
  background-color: #F3F3F3;
  border: none;
  border-radius: 4px;
  height: 22px;
}

.textfield::placeholder {
  font-family: Monte, serif;
  font-style: normal;
  font-weight: bold;
  color: #212F45;
  opacity: 1;
}

.textfield:focus::placeholder {
  color: #212F4540;
}

.textfield--message {
  height: 8rem;
  font-size: 13px;
  resize: none;
}

</style>
