<template>
  <div class="footer">
    <div class="fa-pull-left">
      KFZ-Gutachten. Bei sind Sie richtig!
    </div>
    <div class="fa-pull-right">
      <router-link to="/imprint" tag="a">Impressum</router-link>
      <router-link to="/privacy" tag="a">Datenschutz</router-link>
    </div>
  </div>
</template>

<style scoped>

.footer {
  background-color: #FAFAFA;
}

.fa-pull-left{
  margin-left: 7px;
  color: var(--secondary);
}

a:first-child {
  margin-right: 8px;
  color: var(--secondary);
}

a:last-child {
  margin-right: 14px;
  color: var(--secondary);
}

</style>
