import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        component: () => import('./../view/Index')
    },
    {
        path: '/imprint',
        component: () => import('./../view/Imprint')
    },
    {
        path: '/privacy',
        component: () => import('./../view/Privacy')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;