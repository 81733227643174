import { createApp } from 'vue'
import App from './App.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import router from './router'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

// Add all icons to the library so you can use it in your page
library.add(fas, far, fab)


createApp(App)
    .use(router)
    .use(VueReCaptcha, {siteKey: '6Lcdb3cdAAAAAA-Oe2erK27hel_LJNBECH3V8Jt-'})
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')
